export default function Homepage() {

    //const mapImg = new URL('../public/img/map.jpg?as=webp', import.meta.url)
    return (<>
        <div class="bgimg-1 w3-display-container w3-opacity-min" id="home">
            <div class="w3-display-middle" style={{ whiteSpace: 'nowrap' }}>
                <span class="w3-center w3-padding-large w3-black w3-xlarge w3-wide w3-animate-opacity">FORMATION DIGITAL & SEO</span>
            </div>
        </div>

        <div class="w3-content w3-container w3-padding-64" id="about">
            <h3 class="w3-center">À PROPOS DE MOI</h3>
            <p class="w3-center"><em>Votre Expert en SEO</em></p>
            <p>Fort de plus de 20 ans d'expérience dans le domaine du référencement (SEO), ma carrière est marquée par une passion profonde pour ce métier qui a commencé à l'aube du nouveau millénaire. Au fil des années, j'ai su évoluer avec les changements constants des algorithmes des moteurs de recherche, me forgeant ainsi une expertise solide et reconnue dans le secteur.

En tant que conférencier régulier au Seocamp, je partage mes connaissances approfondies et mes stratégies novatrices avec d'autres professionnels de l'industrie. Ma capacité à transformer des concepts complexes de SEO en stratégies pratiques et applicables est un aspect que je mets en avant dans mes formations.</p>
            <div class="w3-row">
                <div class="w3-col m6 w3-center w3-padding-large">
                    <p><b><i class="fa fa-user w3-margin-right"></i>Muratori David</b></p><br />
                    <img src="/public/img/avatar_hat.jpg?as=webp" class="w3-round w3-image w3-opacity w3-hover-opacity-off" alt="Referenceur SEO nice" width="500" height="333" />
                </div>

                <div class="w3-col m6 w3-hide-small w3-padding-large">
                    <p>Sur mon site, je propose une gamme de formations SEO en présentiel ou en distanciel, conçues pour aider aussi bien les débutants que les experts à améliorer leur visibilité en ligne et à maîtriser les subtilités du référencement. <br/>
                        Que vous soyez une petite entreprise cherchant à booster votre classement sur Google ou un spécialiste du marketing numérique souhaitant peaufiner vos compétences en SEO, mes formations sont là pour vous fournir les outils et les connaissances 
                        nécessaires pour exceller dans ce domaine. <br/> Prêt à transformer votre présence en ligne ? Avec mes formations SEO, démarquez-vous dans le monde digital. Apprenez, évoluez, et dominez le classement sur Google.  <br/>Votre aventure SEO réussie commence ici !</p>
                </div>
            </div>
            <p class="w3-large w3-center w3-padding-16">Mes compétences numériques.</p>
            <p class="w3-wide"><i class="fa fa-camera"></i>SEO</p>
            <div class="w3-light-grey">
                <div class="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: 99 + '%' }}>99%</div>
            </div>
            <p class="w3-wide"><i class="fa fa-camera"></i>SEA</p>
            <div class="w3-light-grey">
                <div class="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: 90 + '%' }}>90%</div>
            </div>
            <p class="w3-wide"><i class="fa fa-laptop"></i>Web Design</p>
            <div class="w3-light-grey">
                <div class="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: 85 + '%' }}>85%</div>
            </div>
            <p class="w3-wide"><i class="fa fa-photo"></i>Photoshop</p>
            <div class="w3-light-grey">
                <div class="w3-container w3-padding-small w3-dark-grey w3-center" style={{ width: 80 + '%' }}>80%</div>
            </div>
        </div>


        <div class="w3-container">
 







        <div class="w3-row w3-center w3-dark-grey w3-padding-16">
            <div class="w3-quarter w3-section">
                <span class="w3-xlarge">20+</span><br />
                Années expériences
            </div>
            <div class="w3-quarter w3-section">
                <span class="w3-xlarge">30+</span><br />
                Clients
            </div>
            <div class="w3-quarter w3-section">
                <span class="w3-xlarge">1+</span><br />
                Formation Proposée
            </div>
            <div class="w3-quarter w3-section">
                <span class="w3-xlarge">1+</span><br />
                Formation réalisée
            </div>
        </div>

        <div class="bgimg-2 w3-display-container w3-opacity-min">
        
        </div>


        <div class="w3-content w3-container w3-padding-64" id="portfolio">
            <h3 class="w3-center">MES FORMATIONS DIGITAL</h3>
            <p class="w3-center"><em>Trouvez ci-dessous l'ensemble des formations SEO <br />que je propose en présentiel ou en distanciel.</em></p><br />

           
           
            <table class="w3-table-all">
    <tr>
      <th>Formation disponible</th>
      <th>Durée</th>
      <th>Fiche de formation</th>
      <th class="w3-center">Tarifs</th>
    </tr>
    <tr>
      <td>Développer sa stratégie de communication digitale (identité, persona...)</td>
      <td>1 jours</td>
      <th><a href="/public/pdf/programme-de-formation-referencement-naturel-qualiopi.pdf">Consulter</a></th>
      <td class="w3-center">600€</td>
    </tr>
    <tr>
      <td>Créer son site web de A à Z en HTML ou Wordpress</td>
      <td>3-5 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Créer son site web Ecommerce avec Prstashop ou Woocommerce</td>
      <td>5 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Apprendre à faire des photos produits et les retoucher avec photoshop</td>
      <td>3 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Apprendre les bases de photoshop</td>
      <td>3 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>

  </table>
</div>



        <div class="w3-content w3-container w3-padding-64" id="portfolio">
            <h3 class="w3-center">MES FORMATIONS SEO</h3>
            <p class="w3-center"><em>Trouvez ci-dessous l'ensemble des formations SEO <br />que je propose en présentiel ou en distanciel.</em></p><br />

           
           
            <table class="w3-table-all">
    <tr>
      <th>Formation disponible</th>
      <th>Durée</th>
      <th>Fiche de formation</th>
      <th class="w3-center">Tarifs</th>
    </tr>
    <tr>
      <td>Augmenter votre trafic grâce au référencement naturel (SEO)</td>
      <td>1 jours</td>
      <th><a href="/public/pdf/programme-de-formation-referencement-naturel-qualiopi.pdf">Consulter</a></th>
      <td class="w3-center">600€</td>
    </tr>
    <tr>
      <td>Formation SEO Niveau 1</td>
      <td>5 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Formation SEO Niveau 2</td>
      <td>3 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>

  </table>
</div>




<div class="w3-content w3-container w3-padding-64" id="portfolio">
            <h3 class="w3-center">MES FORMATIONS BUREATIQUE</h3>
            <p class="w3-center"><em>Trouvez ci-dessous l'ensemble des formations SEO <br />que je propose en présentiel ou en distanciel.</em></p><br />

           
           
            <table class="w3-table-all">
    <tr>
      <th>Formation disponible</th>
      <th>Durée</th>
      <th>Fiche de formation</th>
      <th class="w3-center">Tarifs</th>
    </tr>
    <tr>
      <td>Développer sa stratégie de communication digitale (identité, persona...)</td>
      <td>1 jours</td>
      <th><a href="/public/pdf/programme-de-formation-referencement-naturel-qualiopi.pdf">Consulter</a></th>
      <td class="w3-center">600€</td>
    </tr>
    <tr>
      <td>Créer son site web de A à Z en HTML ou Wordpress</td>
      <td>3-5 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Créer son site web Ecommerce avec Prstashop ou Woocommerce</td>
      <td>5 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Apprendre à faire des photos produits et les retoucher avec photoshop</td>
      <td>3 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>
    <tr>
      <td>Apprendre les bases de photoshop</td>
      <td>3 jours</td>
      <th>Bientôt Disponible</th>
      <td class="w3-center">NC</td>
    </tr>

  </table>
</div>





           

<p class="w3-center">
    <p>
    Plusieurs possibilités de financement existent selon votre activité : AGEFICE, FIF PL, FAFCEA..
    </p>
   <a href="/public/pdf/certificat-formation-qualiopi.pdf"> <img src="/public/img/formation-cetifie-qualiopi.webp" alt="Formation Qualiopi" /> </a><br /><br />
<i class="fa fa-file-pdf-o" aria-hidden="true"></i><em><a href="/public/pdf/reglement-interieur.pdf">Télécharger le Réglement intèrieur</a></em><br />
<i class="fa fa-wheelchair" aria-hidden="true"></i><em><a href="/public/pdf/charte-accompagnement-stagiaires-situation-handicap.pdf">Charte d’accueil et d’accompagnement de l’apprenant en situation de handicap</a></em><br />
</p><br />    
     


           
      
        </div>

        

        <div id="modal01" class="w3-modal w3-black" onclick="this.style.display='none'">
            <span class="w3-button w3-large w3-black w3-display-topright" title="Close Modal Image"><i class="fa fa-remove"></i></span>
            <div class="w3-modal-content w3-animate-zoom w3-center w3-transparent w3-padding-64">
                <img id="img01" class="w3-image" />
                <p id="caption" class="w3-opacity w3-large"></p>
            </div>
        </div>

        <div class="bgimg-3 w3-display-container w3-opacity-min">
            <div class="w3-display-middle">
                <span class="w3-xxlarge w3-text-white w3-wide">CONTACT</span>
            </div>
        </div>

        <div class="w3-content w3-container w3-padding-64" id="contact">
            <h3 class="w3-center">DEMANDE D'INFORMATION</h3>
            <p class="w3-center"><em>N'hésitez pas à me poser une question</em></p>

            <div class="w3-row w3-padding-32 w3-section">
                <div class="w3-col m4 w3-container">
                    <img src="/public/img/map.jpg?as=webp" class="w3-image w3-round" style={{ width: 100 + '%' }} />
                </div>
                <div class="w3-col m8 w3-panel">
                    <div class="w3-large w3-margin-bottom">
                        <i class="fa fa-map-marker fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> 63 Chemin de la maure, 06800 Cagnes sur Mer<br />
                        <i class="fa fa-phone fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Phone: +33 6 26 37 45 77<br />
                        <i class="fa fa-envelope fa-fw w3-hover-text-black w3-xlarge w3-margin-right"></i> Email: datchdigital@gmail.com<br />
                    </div>
                    <p>Passez me voir pour une tasse de <i class="fa fa-coffee"></i>, pour discuter de votre futur formation :</p>
                    <form action="/action_page.php" target="_blank">
                        <div class="w3-row-padding" style={{ margin: '0 -16px 8px -16px' }}>
                            <div class="w3-half">
                                <input class="w3-input w3-border" type="text" placeholder="Name" required name="Name" />
                            </div>
                            <div class="w3-half">
                                <input class="w3-input w3-border" type="text" placeholder="Email" required name="Email" />
                            </div>
                        </div>
                        <input class="w3-input w3-border" type="text" placeholder="Message" required name="Message" />
                        <button class="w3-button w3-black w3-right w3-section" type="submit">
                            <i class="fa fa-paper-plane"></i> ENVOYER
                        </button>
                    </form>
                </div>
            </div>
        </div>
    </>
    )
}