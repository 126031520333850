
export default function Footer() {
    return (
        <footer class="w3-center w3-black w3-padding-64 w3-opacity w3-hover-opacity-off">
            <a href="#home" class="w3-button w3-light-grey"><i class="fa fa-arrow-up w3-margin-right"></i>Remonter</a>
            <div class="w3-xlarge w3-section">
          
                <a href="https://www.linkedin.com/in/david-muratori-64ba646b/"><i class="fa fa-linkedin w3-hover-opacity"></i></a>
            </div>
            <p>Muratori David - Entreprise  Individuelle - 63 chemin de la maure, 06800 CAGNES-SUR-MER <br />
TÉL : +33 (0) 6 26 37 45 77 - Mail : datchdigital@gmail.com<br />
SIRET : 50952464100024 - APE : 7022Z - N° TVA Intracommunautaire : FR17509524641<br />
Numéro de déclaration d’activité : 93061073706  délivré par la préfecture de région Sud. <br />
Cet enregistrement ne vaut pas agrément de l’Etat<br /><br />

<a href="/public/pdf/CGV.pdf">Conditions Générales de Vente</a>
 </p>
        </footer>
    )
}